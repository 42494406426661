import React, { useState } from "react";
import CardHead from "./CardHead";
import SearchPractice from "./SearchPractice";
import SearchSpecialty from "./SearchSpecialty";

function SearchCard() {
  const [toggle, setToggler] = useState(false);

  return (
    <>
      <div
        className="d-flex flex-column mx-auto
     justify-content-center col-12 col-md-10 col-xxl-8 position-relative"
      >
        <ul
          className="nav nav-pills mb-3 d-flex 
        flex-column flex-sm-row justify-content-between align-items-center searchNav p-2"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              onClick={(e) => setToggler(false)}
              className="nav-link active fw-bolder px-4 py-2 "
              id="pills-speciality-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-speciality"
              type="button"
              role="tab"
              aria-controls="pills-speciality"
              aria-selected="true"
            >
              By Speciality{" "}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              onClick={(e) => setToggler(true)}
              className="nav-link fw-bolder px-4 py-2"
              id="pills-practice-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-practice"
              type="button"
              role="tab"
              aria-controls="pills-practice"
              aria-selected="false"
            >
              By Name or Practice
            </button>
          </li>
        </ul>
        <div className="card card-search p-4">
          <div className="tab-content p-sm-3" id="pills-tabContent">
            {" "}
            <CardHead />
            <div
              className="d-flex flex-column justify-content-center align-items-center gap-4 "
              aria-labelledby="pills-speciality-tab"
            >
              {toggle ? <SearchPractice /> : <SearchSpecialty />}{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchCard;
