import React, { useContext, useEffect, useState } from "react";
// import ResultCard from "./Results";
import besalogo from "../../Images/besalogo.png";
import Slots from "./Slots";
import SelectDate from "./SelectDate";
import StateContext from "../../StateContext";
import { Doctor } from "api/Interface";

function ResultCards(props: { doctors: Doctor | any }) {
  const appState = useContext(StateContext);
  const [getDate, setGetDate] = useState(new Date().toDateString());

  useEffect(() => {
    if (appState.selectDate) {
      setGetDate(new Date(appState.selectDate).toDateString());
    }
  }, [getDate, appState.selectDate]);

  return (
    <>
      <div className="pt-4 position-relative">
        {(props.doctors || []).map((doctor: Doctor, index: number) => (
          <div className="card result-card mb-4" key={index}>
            <div className="row my-3 mx-4 pb-3 pb-lg-0">
              <div className="details col-12 col-lg-6 row d-flex justify-content-center align-items-center pb-4">
                <div className="profile-pic bg-secondary col-12  col-md-2 d-flex align-items-center justify-content-center position-relative">
                  <img
                    src={doctor.profile_img ? doctor.profile_img : besalogo}
                    alt=""
                    className="profile-pic position-absolute"
                  />
                </div>
                <div className="col-12  col-md-8 text-center text-md-left">
                  <h6 className="h4* text-center* p-0 m-0 mb-1 mt-2">
                    {doctor.name}{" "}
                  </h6>
                  <p className="text-secondary m-0 mb-1 text-truncate">
                    {doctor.specialty_name}{" "}
                  </p>
                  <span className="badge bg-success">Open</span>
                </div>
                <div
                  className="h-75 col-1 d-none d-lg-block"
                  style={{ borderRight: "1px solid #5CB5E9" }}
                ></div>
              </div>
              <div className="col-12 col-lg-6 slots d-flex d-lg-block justify-content-center align-items-center">
                <div className="row d-flex justify-content-center align-items-center ">
                  <div className="col-12 col-lg-10 ">
                    <h6 className="text-secondary text-center">
                      Slots Available for{" "}
                      {getDate === new Date().toDateString()
                        ? "Today"
                        : getDate}
                    </h6>

                    {doctor.available_slots.map((slots: object, index: any) => (
                      <Slots slots={slots} key={index} />
                    ))}
                  </div>
                  <SelectDate />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ResultCards;
