export interface Action<T> {
  type: T;
}
interface FacilityLayout {
  backgroundColorClass: string;
  cardColor: { backgroundColor: string };
  button: {
    slotActive: string;
    slotBorderColor: string;
    dateButton: string;
  };
}

export interface StateType {
  LoggedIn: boolean;
  user: object;
  selectedDoctor: object;
  selectDate: string | null;
  serviceType: object | null;
  Location: object;
  Domain: string;
  facilityLayout: FacilityLayout;
}

export const initialState: StateType = {
  LoggedIn: false,
  user: {},
  selectedDoctor: {},
  serviceType: null,
  Location: {},
  Domain: " ",
  selectDate: null,
  facilityLayout: {
    backgroundColorClass: "body-default",
    cardColor: { backgroundColor: "" },
    button: {
      slotActive: "available-date-default",
      slotBorderColor: "available-date-default-border",
      dateButton: "select-date-default",
    },
  },
};

export interface StateAction
  extends Action<
    | "LOGIN"
    | "DOMAIN"
    | "SPECIALTY"
    | "LOCATION"
    | "NAME_PRACTICE"
    | "FACILITY_LAYOUT"
    | "LOGOUT"
    | "SELECT_DATE"
  > {
  payload: {
    LoggedIn: boolean;
    user: object;
    selectedDoctor: object;
    serviceType: object | null;
    Location: object;
    facilityLayout: FacilityLayout;
    Domain: string;
    selectDate: string | null;
  };
}

export const reducer = (
  state: StateType,
  action: any | StateAction
): StateType => {
  // eslint-disable-next-line
  switch (action.type) {
    case "LOGIN":
      return { ...state, LoggedIn: true, user: action.payload };
    case "DOMAIN":
      return { ...state, Domain: action.payload };
    case "SPECIALTY":
      return { ...state, serviceType: action.payload };
    case "LOCATION":
      return { ...state, Location: action.payload };

    case "NAME_PRACTICE":
      return { ...state, selectedDoctor: action.payload };
    case "SELECT_DATE":
      return { ...state, selectDate: action.payload };

    case "FACILITY_LAYOUT":
      return { ...state, facilityLayout: action.payload };
    case "LOGOUT":
      return { ...state, LoggedIn: false, user: {} };

    default:
      return state;
  }
};
