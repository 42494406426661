import React, { useContext, useEffect, useState } from "react";
import StateContext from "../../StateContext";
import BookingForm from "./BookingForm";

function BookingModal() {
  const appState = useContext(StateContext);

  const [getDate, setGetDate] = useState(new Date().toDateString());

  useEffect(() => {
    if (appState.selectDate) {
      setGetDate(appState.selectDate);
    }
  }, [appState]);

  return (
    <div
      className="modal fade"
      id="bookingForm"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="bookingFormLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content ">
          <div
            className={`modal-header text-white ${appState.facilityLayout?.button.dateButton}`}
          >
            <p className="modal-title fw-bolder h4" id="bookingFormLabel">
              <small>Book your appointment For :</small> Thursday, {getDate}({" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // @ts-expect-error
                  $("#bookingForm").modal("hide");
                  // @ts-expect-error

                  $("#showCalender").modal("show");
                }}
              >
                change
              </span>{" "}
              ){" "}
            </p>
            <div className="d-flex justify-content-end align-items-center">
              <button
                type="button"
                className="btn-close bg-warning ms-auto"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>{" "}
          </div>
          <div className="modal-body">
            <BookingForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingModal;
