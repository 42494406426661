import React, { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import { DateType } from "react-infinite-calendar";
import StateContext from "../../StateContext";
import DispatchContext from "../../DispatchContext";

function SelectDate() {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [getDate] = useState(new Date());
  const [showCalender, setShowCalender] = useState<boolean>(false);

  useEffect(() => {}, [getDate]);

  return (
    <>
      <button
        onClick={() => setShowCalender(!showCalender)}
        className={`badge col-12 col-md-2 btn select-date d-none rounded-circle  
        d-lg-flex align-items-center justify-content-center 
        ${appState.facilityLayout.button.dateButton}`}
        data-bs-toggle="modal"
        data-bs-target="#showCalender"
      >
        <i className="bi bi-arrow-right"></i>
      </button>

      <button
        onClick={() => setShowCalender(!showCalender)}
        className={`btn badge col-10 col-sm-5  
        d-flex align-items-center d-lg-none justify-content-center py-2
        ${appState.facilityLayout.button.dateButton}`}
        data-bs-toggle="modal"
        data-bs-target="#showCalender"
      >
        See others
      </button>
      <div
        className="modal fade"
        id="showCalender"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg*">
          <div className="modal-content p-2 ">
            <button
              type="button"
              className="btn-close ms-auto"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body">
              <Calendar
                minDate={new Date()}
                onChange={(value: DateType, event: any) => {
                  // @ts-expect-error
                  $("#showCalender").modal("hide");
                  appDispatch({
                    type: "SELECT_DATE",
                    payload: new Date(value).toISOString().slice(0, 10),
                  });
                }}
                value={getDate}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SelectDate;
