/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from "react";
import * as Api from "api/index";
import { Doctor } from "api/Interface";

function SearchPractice() {
  const [doctors, setDoctors] = useState<Doctor | any>([]);
  const [searchDoctor, setSearchDoctor] = useState<string>("Dr");

  useEffect(() => {
    Api.getDoctors().then((doctorsList) => {
      setDoctors(doctorsList);
    });
  }, [searchDoctor]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    //get doctor name, remove spaces sign and replace them with plus signs
    window.location.href = `/search/${searchDoctor?.replace(/\s+/g, "+")}`;
  };

  return (
    <>
      <form
        className="d-flex flex-row col-12 mx-sm-5 px-sm-5 row"
        onSubmit={onSubmit}
      >
        <div className="col-12 col-sm-10 col-md-10 position-relative">
          <input
            list="doctors"
            name="search"
            type="text"
            className="form-control p-3 rounded-end rounded-pill select-practice"
            style={{ border: " 1px solid #FDE408" }}
            id="search"
            onChange={(e) => setSearchDoctor(e.target.value)}
          />
          <datalist id="doctors">
            {doctors.map((doctor: Doctor) => (
              <option value={doctor.name} key={doctor.id} />
            ))}{" "}
          </datalist>

          <button
            type="submit"
            className="btn d-sm-none btn-primary p-3 border-0 rounded-start rounded-pill position-absolute search-location"
            style={{ background: " #FDE408" }}
          >
            <i className="bi bi-search h5 fw-bolder text-dark"></i>{" "}
          </button>
        </div>
        <div className="col-2">
          {" "}
          <button
            type="submit"
            className={`btn d-none d-sm-block btn-primary p-3 border-0 rounded-start rounded-pill`}
            style={{ background: " #FDE408" }}
          >
            <i className="bi bi-search h5 fw-bolder text-dark"></i>{" "}
          </button>
        </div>
      </form>
    </>
  );
}

export default SearchPractice;
