import React from "react";
import Svg from "../../Images/Svg";

function CardHead() {
  return (
    <div className="d-flex flex-column mx-md-5 card-texts">
      <Svg />
      <div
        className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center
        gap-md-5 pt-3 pb-5"
      >
        <h1 className="border-end fw-bolder card-title col-12 col-md-6">
          Find And Book Health Appointments
        </h1>
        <p className="text-secondary col-12 col-md-6 pt-3 pt-sm-0 card-sub-title">
          Specialist and General Practitioners on standby to assist you.
        </p>
      </div>
    </div>
  );
}

export default CardHead;
