import React, { useContext } from "react";
import DispatchContext from "../../DispatchContext";

function Head() {
  const appDispatch = useContext(DispatchContext);

  return (
    <ul
      className="nav nav-pills mb-3 d-flex 
        flex-row justify-content-between align-items-center searchNav p-2"
      id="pills-tab"
      role="tablist"
    >
      <div className="nav-item d-none d-sm-block" role="presentation">
        <button
          className="nav-link active fw-bolder px-4 py-2"
          id="pills-specialty-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-specialty"
          type="button"
          role="tab"
          aria-controls="pills-specialty"
          aria-selected="true"
        >
          By Specialty{" "}
        </button>
      </div>
      <div className="nav-item col-12 col-sm-7 " role="presentation">
        <select
          className="form-select p-3* rounded-5 rounded-pill"
          aria-label="Default select example"
          onChange={(e) =>
            appDispatch({ type: "SPECIALTY", payload: e.target.value })
          }
        >
          <option defaultValue="">Specialty</option>
          <option value="Therapist">Therapist</option>
          <option value="Audiology">Audiology</option>
          <option value="CardioVascular">CardioVascular</option>
          <option value="Cardiology">Cardiology</option>
          <option value="COVID-19 Testing">COVID-19 Testing</option>
          <option value="Physiotherapy">Physiotherapy</option>
          <option value="Dentistry">Dentistry</option>
        </select>
      </div>
    </ul>
  );
}

export default Head;
