import React, { useContext, useState } from "react";
import StateContext from "../../StateContext";
import { bookerDetails } from "./Interface";

function BookingForm() {
  const appState = useContext(StateContext);
  const [bookerDetails, setBookerDetails] = useState<bookerDetails>({
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
    appointment_type: null,
    visited: true,
    referralDetails: {
      first_name: null,
      last_name: null,
      medical_center: null,
    },
    referred: true,
    slot: null,
  });

  const BookAppointment = (e: any) => {
    e.preventDefault();
    // @ts-expect-error
    document.getElementById("booking").reset();
    // @ts-expect-error
    $("#bookingForm").modal("hide");
    //route to home Page after submission
    window.location.href = `/`;
  };
  return (
    <form className="row" onSubmit={BookAppointment} id="booking">
      <div className="mb-4 col-12 col-md-6">
        <label className="form-label">First Name</label>
        <input
          required
          onChange={(e) =>
            setBookerDetails({
              ...bookerDetails,
              first_name: e.target.value,
            })
          }
          type="text"
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      </div>
      <div className="mb-4 col-12 col-md-6">
        <label className="form-label">Last Name</label>
        <input
          required
          onChange={(e) =>
            setBookerDetails({
              ...bookerDetails,
              last_name: e.target.value,
            })
          }
          type="text"
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      </div>
      <div className="col-12 col-md-6 mb-4">
        <label className="form-label">Phone Number</label>
        <input
          required
          onChange={(e) =>
            setBookerDetails({
              ...bookerDetails,
              phone: e.target.value,
            })
          }
          type="tel"
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      </div>
      <div className="col-12 col-md-6 mb-4">
        <label className="form-label">Email address</label>
        <input
          required
          onChange={(e) =>
            setBookerDetails({
              ...bookerDetails,
              email: e.target.value,
            })
          }
          type="email"
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      </div>{" "}
      <div className="mb-4 col-12">
        <label className="form-label">Type of Appointment </label>
        <select
          required
          className="form-select "
          aria-label="Default select example"
          onChange={(e) =>
            setBookerDetails({
              ...bookerDetails,
              appointment_type: e.target.value,
            })
          }
        >
          <option defaultValue="">Select Type of Appointment </option>
          <option value="Therapist">Therapist</option>
          <option value="Audiology">Audiology</option>
          <option value="CardioVascular">CardioVascular</option>
          <option value="Cardiology">Cardiology</option>
          <option value="COVID-19 Testing">COVID-19 Testing</option>
          <option value="Physiotherapy">Physiotherapy</option>
          <option value="Dentistry">Dentistry</option>
        </select>
      </div>
      <div className="form-check col-12 col-md-6  mb-4 form-switch  ">
        <small>Have you visited this practice before? </small> <br />
        <input
          onClick={(e) => {
            setBookerDetails({
              ...bookerDetails,
              visited: !bookerDetails.visited,
            });
          }}
          className="form-check-input mx-1"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckDefault"
          defaultChecked={bookerDetails.visited}
        />
        <label className="form-check-label px-3">
          {bookerDetails.visited ? "Yes" : "No"}{" "}
        </label>
      </div>
      <div className="form-check col-12 col-md-6  mb-4 form-switch ">
        <small>Were you referred by a doctor? </small> <br />
        <input
          onClick={(e) => {
            setBookerDetails({
              ...bookerDetails,
              referred: !bookerDetails.referred,
            });
          }}
          className="form-check-input mx-1"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckChecked"
          defaultChecked={bookerDetails.referred}
        />
        <label className="form-check-label px-3">
          {" "}
          {bookerDetails.referred ? "Yes" : "No"}{" "}
        </label>
      </div>
      <div
        className={bookerDetails.referred ? `mb-4 col-12 col-md-6` : "d-none"}
      >
        <label className="form-label">Referral Doctor First Name</label>
        <input
          required={bookerDetails.referred}
          onChange={(e) =>
            setBookerDetails({
              ...bookerDetails,
              referralDetails: {
                first_name: e.target.value,
                last_name: bookerDetails.referralDetails.last_name,
                medical_center: bookerDetails.referralDetails.medical_center,
              },
            })
          }
          type="text"
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      </div>
      <div
        className={bookerDetails.referred ? `mb-4 col-12 col-md-6` : "d-none"}
      >
        <label className="form-label">Referral Doctor Last Name</label>
        <input
          required={bookerDetails.referred}
          onChange={(e) =>
            setBookerDetails({
              ...bookerDetails,
              referralDetails: {
                first_name: bookerDetails.referralDetails.first_name,
                last_name: e.target.value,
                medical_center: bookerDetails.referralDetails.medical_center,
              },
            })
          }
          type="text"
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      </div>
      <div className={bookerDetails.referred ? "col-12  mb-4" : "d-none"}>
        <label className="form-label">Medical Center</label>
        <input
          required={bookerDetails.referred}
          onChange={(e) =>
            setBookerDetails({
              ...bookerDetails,
              referralDetails: {
                first_name: bookerDetails.referralDetails.first_name,
                last_name: bookerDetails.referralDetails.last_name,
                medical_center: e.target.value,
              },
            })
          }
          type="text"
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      </div>
      <button
        type="submit"
        className={`btn py-2 col-8 mx-auto btn-warning l${appState.facilityLayout?.button.dateButton}`}
      >
        Submit
      </button>
    </form>
  );
}

export default BookingForm;
