import { useEffect, useReducer } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { reducer, initialState } from "./utils/reducers-state";
import DispatchContext from "./DispatchContext";
import StateContext from "./StateContext";
import Nav from "./Nav";
import Home from "./component/Home/Index";
import Results from "./component/Results/Index";
// import Footer from "./Footer";
// import * as Api from "api/index";

const App: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let backgroundColor = document.querySelector<HTMLElement>(".main-body")!;

    //Add background color from state
    backgroundColor.classList.add(
      `${state.facilityLayout?.backgroundColorClass}`
    );
  }, [state.facilityLayout]);

  return (
    <>
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          <div className={`container-fluid container-main position-relative`}>
            <Nav />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/search/:specialty" element={<Results />} />
            </Routes>
            {/* <Footer /> */}
          </div>
        </DispatchContext.Provider>
      </StateContext.Provider>
    </>
  );
};

export default App;
