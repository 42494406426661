import besalogo from "../../Images/besalogo.png";

function Loader() {
  return (
    <div className=" text-center mt-5 p-5" role="status">
      <img src={besalogo} alt="" className="spinner-grow sr-only" />{" "}
    </div>
  );
}

export default Loader;
