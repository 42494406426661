import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardHead from "./CardHead";
import ResultCards from "./ResultCards";
import Loader from "../Loader/Loader";
import StateContext from "../../StateContext";
import * as Api from "api/index";
import { Doctor } from "api/Interface";

import BookingModal from "component/Form/BookingModal";

function ResultCard() {
  const appState = useContext(StateContext);

  const [doctors, setDoctors] = useState<Doctor | any>([]);
  const [loading, setLoading] = useState<boolean>();

  let { specialty } = useParams();

  useEffect(() => {
    setLoading(true);
    Api.getDoctors().then(async (doctorsList) => {
      if (
        Api.getDoctorByName(specialty?.replace(/[+]/gi, " "), doctorsList)
          .length !== 0
      ) {
        setDoctors(
          await Api.getDoctorByName(
            specialty?.replace(/[+]/gi, " "),
            doctorsList
          )
        );
      } else if (appState.serviceType !== null) {
        setDoctors(await Api.getSpecialty(appState.serviceType, doctorsList));
      } else {
        setDoctors(await Api.getSpecialty(specialty, doctorsList));
      }

      setLoading(false);
    });

    Api.getDoctors();
  }, [specialty, appState.serviceType, doctors]);

  return (
    <div className="pt-3 mt-3* mx-md-5 px-md-5">
      <CardHead />
      {loading ? <Loader /> : <ResultCards doctors={doctors} />}
      <BookingModal />
    </div>
  );
}

export default ResultCard;
