import { Doctor } from "./Interface";
const axios = require("axios");

// set variables
let data: Doctor;
let domain: string;
let searchedDoctors: Doctor | any = [];

export const getDoctors = async () => {
  await axios
    .get("https://trial.besacare.com/api/v1/mobile/public/doctors.json/")
    .then((res: any) => {
      data = res.data;
    })
    .catch((err: any) => {
      console.log(err.message);
      console.log(err);
    });

  return data;
};

export const checkDomain = () => {
  if (
    window.location.href !== "http://localhost:3000/" ||
    "https://besacare.vercel.app/"
  ) {
    domain = "alias";
  }
  return domain;
};

export const getSpecialty = (query: string | any, doctors: Doctor | any) => {
  doctors.forEach((doctor: Doctor) => {
    if (doctor.specialty_name.includes(query)) {
      searchedDoctors.push(doctor);
    }
  });

  return searchedDoctors;
};

export const getDoctorByName = (query: string | any, doctors: Doctor | any) => {
  doctors.forEach((doctor: Doctor) => {
    if (doctor.name.includes(query)) {
      searchedDoctors.push(doctor);
    }
  });

  return searchedDoctors;
};

export const createSubDomain = async (subdomain: string) => {
  await axios
    .post(
      `https://api.vercel.com/v8/projects/${process.env.REACT_APP_PRODUCT_ID}/domains?teamId=${process.env.REACT_APP_TEAM_ID}`,
      {
        name: `${subdomain}`,
      },
      { headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN}` } }
    )
    .then((res: any) => {
      console.log(res.data);
    })
    .catch((err: any) => {
      console.log(err);
    });
};
