import React, { useState } from "react";
import { Link } from "react-router-dom";

function SearchSpecialty() {
  const [specialty, setSpecialty] = useState<string>("Therapist");

  const onSubmit = (e: any) => {
    e.preventDefault();
    //get doctor name, remove spaces sign and replace them with plus signs
    window.location.href = `/search/${specialty}`;
  };

  return (
    <>
      <form
        onSubmit={onSubmit}
        className="d-flex flex-row col-12 mx-5 px-md-5 row ms-3* justify-content-center*   mx-auto "
      >
        <div className="col-12 col-sm-5 mb-4 col-md-7">
          {" "}
          <select
            style={{ background: " #CEE9F8" }}
            className="form-select p-3 rounded-end rounded-pill select-specialty"
            aria-label="Default select example"
            onChange={(e) => setSpecialty(e.target.value)}
          >
            <option defaultValue="" disabled>
              Specialty
            </option>
            <option value="Therapist">Therapist</option>
            <option value="Audiology">Audiology</option>
            <option value="CardioVascular">CardioVascular</option>
            <option value="Cardiology">Cardiology</option>
            <option value="COVID-19">COVID-19</option>
            <option value="Physiotherapy">Physiotherapy</option>
            <option value="Dentistry">Dentistry</option>
          </select>
        </div>
        <div className="col-12 col-sm-5 mb-4 mx-2 mx-md-0 col-md-3 position-relative">
          <input
            list="location"
            id="myLocation"
            name="myLocation"
            className="form-control p-3"
            style={{
              border: " 1px solid #FDE408",
            }}
          />
          <datalist id="location">
            <option value="Chrome" />
            <option value="Firefox" />
            <option value="Internet Explorer" />
            <option value="Opera" />
            <option value="Safari" />
            <option value="Microsoft Edge" />
          </datalist>

          <button
            type="submit"
            className="btn d-sm-none p-3 border-0 rounded-start rounded-pill position-absolute search-location"
            style={{ background: " #FDE408" }}
          >
            <i className="bi bi-search h5 fw-bolder text-dark"></i>{" "}
          </button>
        </div>
        <div className="d-none d-sm-block col-12 col-sm-1 mb-4 col-md-2">
          {" "}
          <Link
            // type="submit"
            to={`/search/${specialty}`}
            className="btn  p-3 border-0 rounded-start rounded-pill"
            style={{ background: " #FDE408" }}
          >
            <i className="bi bi-search h5 fw-bolder text-dark"></i>{" "}
          </Link>
        </div>
      </form>
    </>
  );
}

export default SearchSpecialty;
