import React, { useContext, useEffect, useState } from "react";
import StateContext from "../../StateContext";

function Slots(props: { slots: object }) {
  const appState = useContext(StateContext);
  const [active, setActive] = useState("1");
  const [getToday] = useState(new Date().toISOString().slice(0, 10));
  const [availableSlots, setAvailableSlots] = useState<[]>([]);

  useEffect(() => {
    getTodaysSlot(props.slots, getToday);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getToday,
    props.slots,
    appState.facilityLayout.button,
    appState.selectDate,
  ]);

  const getTodaysSlot = (slotArray: any, currentDate: string) => {
    // eslint-disable-next-line array-callback-return
    Object.keys(slotArray).map((slotDate: any) => {
      if (currentDate === slotDate) {
        setAvailableSlots(slotArray[slotDate]);
      } else if (slotDate === appState.selectDate) {
        setAvailableSlots(slotArray[slotDate]);
      }
    });
  };

  return (
    <div className="available row pt-3">
      {availableSlots.map((availableSlot: any) => (
        <div
          className="col-xs-4 col-sm-2 col-md-2 col-lg-3 mx-sm-2 mx-lg-2  pt-2 pb-3 px-4 px-md-0* d-flex d-sm-block justify-content-center align-items-center"
          key={availableSlot.slot}
        >
          <button
            data-bs-toggle="modal"
            data-bs-target="#bookingForm"
            onClick={() => setActive(availableSlot.slot)}
            className={
              active === availableSlot.slot
                ? `btn available-date p-0 text-center d-flex align-items-center ${appState.facilityLayout.button.slotActive}  ${appState.facilityLayout.button.slotBorderColor} justify-content-center active`
                : `btn available-date p-0 text-center d-flex align-items-center ${appState.facilityLayout.button.slotActive}  ${appState.facilityLayout.button.slotBorderColor} justify-content-center`
            }
          >
            <small className="text-secondary">{availableSlot.period}</small>
          </button>
        </div>
      ))}
    </div>
  );
}

export default Slots;
